import { createRouter, createWebHashHistory } from "vue-router";
import VueCookies from "vue-cookies";

//必须填写name
const routes = [
  {
    path: "/newCar",
    name: "newCar",
    component: () => import(`@/views/newCar/index`),
  },
  {
    path: '/vehicleHome',
    name: 'vehicleHome',
    component: () => import(`@/views/vehicleHome/index`)
  },
  {
    path: '/vehicleCY',
    name: 'vehicleCY',
    component: () => import(`@/views/vehicleHome/cyversion`)
  },
  // 订单详情
  {
    path: "/orderDeatil",
    name: "orderDeatil",
    meta: {
      title: '订单详情'
    },
    component: () => import(`@/views/orderDeatil/orderDeatil`),
  },
  // 我的订单
  {
    path: "/myOrder",
    name: "myOrder",
    meta: {
      title: '我的订单'
    },
    component: () => import(`@/views/myOrder/myOrder`),
  },
  //我的预约
  {
    path: "/myPrebook",
    name: "myPrebook",
    meta: {
      title: '我的预约'
    },
    component: () => import(`@/views/deposit/myPrebook`),
  },
  // 协议
  {
    path: "/agreement",
    name: "agreement",
    component: () => import(`@/views/agreement/agreement`),
  },
  {
    path: "/modelSelection",
    name: "modelSelection",
    meta: {
      title: '车型选择'
    },
    component: () => import(`@/views/modelSelection/index`),
  },
  // 收银台
  {
    path: "/cashier",
    name: "cashier",
    meta: {
      title: '收银台',
    },
    component: () => import(`@/views/cashier/index`),
  },
  {
    path: "/successful",
    name: "successful",
    component: () => import(`@/views/successful/index`),
  },
  // 确认订购 定购
  {
    path: "/confirmOrder",
    name: "confirmOrder",
    meta: {
      keepAlive: true,
      title: '配置'
      // navBarStyle: {
      //   transparentStyle: false,
      //   backgroundColor: '#fff'
      // }
    },
    component: () => import(`@/views/confirmOrder/index`),
  },
  // 金融计算器
  {
    path: "/computing",
    name: "computing",
    meta: {
      keepAlive: true,
      title: '金融试算器'
    },
    component: () => import(`@/views/computing/index`),
  },
  // 试驾
  {
    path: "/testDrive",
    name: "testDrive",
    component: () => import(`@/views/testDrive/index`),
  },
  // 立即定购
  {
    path: "/deposit",
    name: "deposit",
    children: [
      // 配置
      {
        path: "config",
        name: "depositConfig",
        component: () => import(`@/views/deposit/Config`),
        meta: {
          keepAlive: true,
          title: '配置'
          // 透明样式 必须是String
        },
        children: [
          {
            path: "list",
            name: "depositConfigList",
            component: () => import(`@/views/deposit/version-list`),
            meta: {
              keepAlive: true,
              title: '配置'
            },
          },
          {
            path: "custom",
            name: "depositConfigCustom",
            component: () => import(`@/views/deposit/car-custom`),
            meta: {
              keepAlive: true,
              title: '配置'
              // navBarStyle: {
              //   transparentStyle: true,
              //   backgroundColor: '#fff'
              // }
            },
          },
        ]
      },
      // 定购列表
      // {
      //   path: "order/list",
      //   name: "depositOrderList",
      //   component: () => import(`@/views/deposit/OrderList`),
      // },
      // 定购详情
      {
        path: "order/detail/:id",
        name: "depositOrderDetail",
        meta:{
          title: '订单详情'
        },
        beforeEnter(to, from, next) {
          // 使用 replace 方法替代页面
          next(vm => {
            // 在路由导航后手动更新页面标题
            document.title = vm.$route.meta.title || '订单详情';
          });
        },
        component: () => import(`@/views/deposit/OrderDetail`),
      }
    ]
  },
  // 心愿单
  {
    path: "/wish",
    name: "wish",
    meta: {
      title: '心愿单'
    },
    component: () => import(`@/views/wish/index`),
  },
  //服务网点
  {
    path: "/serviceNet",
    name: "serviceNet",
    meta: {
      title: '服务网点'
    },
    component: () => import(`@/views/serviceNet/index`),
  },
  //选择城市
  {
    path: "/citySelect",
    name: "citySelect",
    meta: {
      title: '选择城市'
    },
    component: () => import(`@/views/serviceNet/citySelect`),
  },
  // 邀请用户
  {
    path: "/share",
    name: "share",
    meta: {
      title: '邀请用户'
    },
    component: () => import(`@/views/share/index`),
  },
  // 邀请注册
  {
    path: "/shareReg",
    name: "shareReg",
    meta: {
      title: '邀请注册'
    },
    component: () => import(`@/views/share/shareReg`),
  },
  // 预约表单
  {
    path: "/preBook",
    name: "preBook",
    component: () => import(`@/views/preBook/index`),
  },
  // 预约成功
  {
    path: "/preBookSuccess",
    name: "preBookSuccess",
    component: () => import(`@/views/preBook/success`),
  },
  // 充电权益
  {
    path: "/Charging",
    name: "Charging",
    component: () => import(`@/views/Charging/index`),
  },
  // 领取充电权益
  {
    path: "/ChargingSuccess",
    name: "ChargingSuccess",
    component: () => import(`@/views/Charging/result`),
  },
  {
    path: "/download",
    name: "download",
    component: () => import(`@/views/download/index`),
  },
  {
    path: "/calculator",
    name: "calculator",
    meta: {
      title: '省钱计算器'
    },
    component: () => import(`@/views/calculator/index`),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(`@/views/404`),
  },
  {
    // 权益详情页面
    path: "/myrights/detail",
    name: "myRightDetail",
    meta: {
      title: '权益详情'
    },
    component: () => import(`@/views/myRights/detail`),
  },
  { 
    // 权益介绍页面
    path: "/myrights/info",
    name: "myRightsInfo",
    meta: {
      title: '权益详情'
    },
    component: () => import(`@/views/myRights/info`),
  },
  {
    // 订单列表页面
    path: "/myrights/orderlist",
    name: "myRightsOrders",
    component: () => import(`@/views/myRights/orderList`),
  },
  {
    // 权益列表页面
    path: "/myrights/success",
    name: "myRightsSuccess",
    meta: {
      title: '领取成功'
    },
    component: () => import(`@/views/myRights/success`),
  },
  {
    // 权益列表页面
    path: "/myrights/list",
    name: "myRightsList",
    meta: {
      title: '我的权益'
    },
    component: () => import(`@/views/myRights/list`),
  },
  {
    // 权益列表页面
    path: "/myrights/history",
    name: "myRightsHistory",
    meta: {
      title: '历史权益'
    },
    component: () => import(`@/views/myRights/history`),
  },
  {
    path: "/eqxiu/lottery",
    name: "eqxiuLottery",
    component: () => import(`@/views/eqxiu/lottery`),
  },
  {
    path: "/annualReport",
    name: "annualReport",
    component: () => import(`@/views/annualReport`),
  },
  // 大定分享页面
  {
    path: "/orderSharing",
    name: "orderSharing",
    // meta: {
    //   navBarStyle: {
    //     transparentStyle: true,
    //     backgroundColor: 'transparent',
    //   }
    // },
    component: () => import(`@/views/orderSharing`),
  },
];

const router = new createRouter({
  history: createWebHashHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  // 如果未匹配到路由 则跳转全局404
  if (!to.matched.length) {
    next({
      path: "/404",
    });
  }
  // 设置页面标题
  if (to.query.title || to.meta.title) {
    const queryTitle = Array.isArray(to.query.title) ? to.query.title[0] : to.query.title;
    document.title = queryTitle || to.meta.title
  }
  const { aId, rId } = to.query
  if (aId && aId !== 'null' && rId && rId !=="null") {
    VueCookies.set("aId", aId);
    VueCookies.set("rId", rId);
  } 
  next();
});

// 路由加载完成后的记录的日志
router.afterEach(() => { });
export default router;
