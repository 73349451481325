import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Toast } from "vant";
import route from "@/router/index";
import VueCookies from "vue-cookies";
import { AppLogin, JSCallGetLoginInfo, setBarColor, JSCallGetAppBarHeight, JSGetLocationFromApp } from "./jsbridge";
import { areaList } from "@vant/area-data"
import { jsonp } from "vue-jsonp"

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
/**
 * 判断字符串是否为空
 * @param o
 * @returns {Boolean}
 */
export function isNull(o) {
  return o == null || o == "" || o == "undefined" || o == "null";
}

/**
 * 校验是是不是正确的手机号
 * @param {} mobile
 */
export function isMobile(mobile) {
  let mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
  return mobileReg.test(mobile);
}

/**
 * 手机号码脱敏
 * @param str
 * @returns {string}
 */
export function desensitizationFun(str) {
  if (str) {
    return str.replace(/(\d{3})\d{4}(\d{3})/, "$1****$2");
  }
}

/**
 * 判断是否登录
 */
export function isLogin() {
  let accessToken = getParam("aId") || getCookie("aId");
  let refreshToken = getParam("rId") || getCookie("rId");
  if (isNull(accessToken) || isNull(refreshToken)) {
    return false;
  }
  return true;
}
/**
 * 获取cookie
 * @param name
 * @returns {string}
 */
export function getCookie(name) {
  const strcookie = document.cookie; //获取cookie字符串
  const arrcookie = strcookie.split("; "); //分割
  //遍历匹配
  for (let i = 0; i < arrcookie.length; i++) {
    const arr = arrcookie[i].split("=");
    if (arr.length === 2 && arr[0] === name) {
      return decodeURIComponent(arr[1]);
    }
  }
  return "";
}

/**
 * 判断是否是在自有app内打开H5
 */
export const isApp = () => {
  let ua = navigator.userAgent;
  return ua.indexOf("App/Radar") > -1;
};

/**
 * 是否是微信环境
 */
export const isWechatApp = () => {
  let ua = window.navigator.userAgent.toLowerCase();
  return "micromessenger" == ua.match(/MicroMessenger/i);
};

/**
 * 判断是否在微信小程序环境
 * @returns {boolean}
 */
export function isMiniprogramEnv() {
  const agent = navigator.userAgent;
  const isWxapp = /miniProgram/g.test(agent); // 微信小程序
  const isAliapp = /MiniProgram/g.test(agent); //支付宝小程序
  const isBdapp = /swan\//g.test(agent); // 百度小程序
  const isDyapp = /toutiaomicroapp/i.test(agent); // 抖音小程序
  const isThirdapp = isWxapp || isAliapp || isBdapp || isDyapp;
  console.log("agent", agent);
  if (isThirdapp) {
    return true;
  }
  return false;
}
/**
 * 获取当前小程序环境
 * @returns
 */
export function getMiniprogramEnv() {
  const agent = navigator.userAgent;
  const isWxapp = /miniProgram/g.test(agent); // 微信小程序
  const isAliapp = /MiniProgram/g.test(agent); //支付宝小程序
  const isBdapp = /swan\//.test(agent); // 百度小程序
  const isDyapp = /toutiaomicroapp/i.test(agent); // 抖音小程序
  return {
    isWxapp,
    isAliapp,
    isBdapp,
    isDyapp,
  };
}
/**小程序跳转 */
export function miniAppNavigateTo(param) {
  return mpx.navigateTo(param);
}
/**小程序跳转 */
export function miniAppRedirectTo(param) {
  return mpx.redirectTo(param);
}
/**小程序发消息 */
export function miniAppPostMessage(param) {
  return mpx.postMessage(param);
}

/**小程序回退 */
export function miniAppNavigateBack(param) {
  return mpx.navigateBack(param);
}
/** 小程序切换tab */
export function miniAppSwitchTab(param) {
  return mpx.switchTab(param);
}
/**
 * 判断是否是安卓手机
 * @returns {boolean}
 */
export function isAndroid() {
  let ua = navigator.userAgent.toLowerCase();
  //Android终端
  // Toast({message:"agent："+ua,position:"middle"});
  let isAndroid =
    ua.indexOf("Android") > -1 ||
    ua.indexOf("android") > -1 ||
    ua.indexOf("Adr") > -1;
  // Toast({message:"安卓手机："+isAndroid,position:"middle"});
  // console.log("aaaaaaa");
  return isAndroid;
}

/**
 * 判断是否是苹果手机
 * @returns {boolean}
 */
export function isIos() {
  let ua = navigator.userAgent.toLowerCase();
  //Ios终端
  let isiOS = !!ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/);
  return isiOS;
}
/**
 * 是否是苹果浏览器
 */
export const isSafari = () => {
  let ua = navigator.userAgent.toLowerCase();
  if (
    ua.indexOf("applewebkit") > -1 &&
    ua.indexOf("mobile") > -1 &&
    ua.indexOf("safari") > -1 &&
    ua.indexOf("linux") === -1 &&
    ua.indexOf("android") === -1 &&
    ua.indexOf("chrome") === -1 &&
    ua.indexOf("ios") === -1 &&
    ua.indexOf("browser") === -1
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * 申请原生登录
 */
export function applyPrimordialLogin() {
  try {
    // 微信小程序端未登录跳转页面
    if (isMiniprogramEnv()) {
      miniAppRedirectTo({
        url: "/pages/login/authorization",
      });
    } else if (isApp()) {
      AppLogin()
    } else {
      const redirectUrl = encodeURIComponent(clearUrlParams(window.location.href, ['aId', 'rId']))
      window.location.replace(`${process.env.VUE_APP_WAP_PATH}login.html?type=H5XDD&redirect_url=${redirectUrl}`)
    }
  } catch (e) {
    console.log("登录失败,", e);
  }
}

/**
 * 获取URL中参数
 * @id strName 参数名称
 */
export function getParam(id) {
  let param = hasParamByKey(window.location.href, id);
  return param;
}

/**
 * 根据URL和参数key获取URL中的参数值
 * @wapUrl
 * @key
 */
export function hasParamByKey(wapUrl, key) {
  let url = wapUrl;
  if (null == url || "" == url) {
    url = window.location.href;
  }
  let val = "";

  if (url.toString().indexOf("?") != -1) {
    let oUrl = url.split("?");
    let strs = oUrl[1].split("&");
    for (let i = 0; i < strs.length; i++) {
      if (strs[i].split("=")[0].toLowerCase() == key.toLowerCase()) {
        return unescape(
          strs[i].split("=")[1] == "null" ? "" : strs[i].split("=")[1] || ''
        );
      }
    }
  }
  return val;
}

/**
 * 去除重复参数 以最后一个为准
 * @param url
 * @returns {*}
 */
export const removeDuplicateUrlParams = (url) => {
  //为了防止出现重复参数 以最后出现的参数为准
  let paramsNameArray = _.union(
    (url.match(/[?&][a-zA-Z]+=/g) || []).map((v) => v.replace(/[?&=]/g, ""))
  );
  paramsNameArray.forEach((v) => {
    let regex = new RegExp(`&?${v}=[^&]*&?`, "g");
    let paramsArray = (url.match(regex) || []).map((v) =>
      v.replace(/[?&]/g, "")
    );
    url = removeUrlParams(url, v);
    const keepParam = paramsArray[paramsArray.length - 1].split("=");
    url = addUrlParams(url, keepParam[0], keepParam[1]);
  });
  return url;
};

/**
 * 移除某个url参数
 * @param url
 * @param name
 */
export const removeUrlParams = (url, name) => {
  url = url.replace(new RegExp(`${name}=[^&]*&?`, "g"), "");
  if (
    url.lastIndexOf("?") == url.length - 1 ||
    url.lastIndexOf("&") == url.length - 1
  ) {
    url = url.substr(0, url.length - 1);
  }
  return url;
};
/**
 * 添加url参数
 * @param url
 * @param k
 * @param v
 * @returns {string}
 */
export const addUrlParams = (url, k, v) => {
  url = removeUrlParams(url, k);
  //如果最后一位为？则移除
  if (
    url.lastIndexOf("?") == url.length - 1 ||
    url.lastIndexOf("&") == url.length - 1
  ) {
    url = url.substr(0, url.length - 1);
  }
  url = url + ((url.indexOf("?") !== -1 ? "&" : "?") + k + "=" + v);
  return url;
};

/**
 * 添加url参数，是否用addUrlParams代替
 * @param url
 * @param k
 * @param v
 * @returns {string}
 */
export const addUrlParams2 = (url, v) => {
  //如果最后一位为？则移除
  if (url.lastIndexOf("?") > -1) {
    url = url + "&" + v;
  } else {
    url = url + "?" + v;
  }
  return url;
};

/**
 * 时间转换格式
 * @param time 20210131170805
 * @param format 默认格式2021-01-31 17:08:05
 * @returns {string}
 */
export function dateFormat(time, format) {
  let date = time;
  let Y = date.substr(0, 4) + "-";
  let M = date.substr(4, 2) + "-";
  let D = date.substr(6, 2) + " ";
  let h = date.substr(8, 2);
  let m = ":" + date.substr(10, 2);
  let s = ":" + date.substr(12);

  let _format = format ? format : "all";

  // 返回不同时间格式，默认为all
  if (_format == "YMD") {
    return Y + M + D;
  } else if (_format == "MD") {
    return M + D;
  } else if (_format == "YMDhm") {
    return Y + M + D + h + m;
  } else if (_format == "hms") {
    return h + m + s;
  } else if (_format == "hm") {
    return h + m;
  } else {
    return Y + M + D + h + m + s;
  }
}

//将http转为https
export function changeHttptoHttps(str) {
  if (str.indexOf("http") > -1 && str.indexOf("https") == -1) {
    return str.replace(/http/g, "https");
  } else {
    return str;
  }
}

//复制内容
export function copyText(str) {
  var input = document.createElement("input"); // js创建一个input输入框
  input.value = str; // 将需要复制的文本赋值到创建的input输入框中
  document.body.appendChild(input); // 将输入框暂时创建到实例里面
  input.select(); // 选中输入框中的内容
  document.execCommand("Copy"); // 执行复制操作
  document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
}

//替换href链接为方法
export function changeHref(str) {
  let a = str.indexOf('href="h');
  let b = str.indexOf('"');
  let n = 1;
  if (b < a) {
    n = 2;
  }
  if (a > -1) {
    while (n > 0) {
      b = str.indexOf('"', b + 1);
      if (b > a) {
        n = n - 1;
      }
    }
    let url = str.substring(a, b + 1);
    return str.replace(url, 'href="javascript:void(0);"');
  } else {
    return str;
  }
}

/**
 * 页面跳转
 * @param url
 */
export const goUrl = (url = process.env.VUE_APP_HOME_PAGE) => {
  if (url) {
    window.location.href = url;
  }
};

/**
 * 判断是否在时间之前按钮不可点击
 * @param type  1时间段   2 某个时间之前  3 某个时间之后.
 * @param timeList  时间 Aarry  数组格式  日期格式 2022-07-19.
 * @return {boolean}
 */
export const isDisabledBtn = (type, timeList) => {
  let status = false; // 默认可点击
  let nowTimer = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
  //let nowTimer = dayjs('2022-08-22').format('YYYY-MM-DD');
  if (type == "1") {
    status = dayjs(nowTimer).isBetween(
      timeList[0],
      dayjs(timeList[1]),
      "second"
    );
  } else if (type == "2") {
    status = dayjs(nowTimer).isSameOrBefore(timeList[0], "second");
  } else if (type == "3") {
    status = dayjs(nowTimer).isSameOrAfter(timeList[0], "second");
  }
  return status;
};
// 时间格式化状态显示
export function formatTime(time, option) {
  let timer = new Date(time).getTime();
  const d = new Date(timer);
  const now = Date.now();
  const diff = (now - d) / 1000;
  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  if ((time + "").length === 10) {
    time = +time * 1000;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    date = new Date(parseInt(time));
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a")
      return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return str;
}

// 日期格式统一
export function parseFormat(time) {
  return dayjs(time).format("YYYY/MM/DD HH:mm:ss");
}

// 替换编码

export function replaceCode(str, num) {
  str = String(str);
  if (str === 'null' || str === 'undefined') return ''
  let n = "";
  for (var i = 0; i < num; i++) {
    n += "0";
  }
  return str.substr(0, str.length - num) + n;
}

/**
 *
 * @param {Number} price 价格
 * @param {{
*   addPrefix?: boolean,     // 添加货币符号作为前缀
*   customPrefix?: string,   // 自定义前缀
*   emptyText?: string,      // 空价格文本
* }} options
* @returns {String} 格式化文本
*/
export function formatPrice(price, options = {}) {
  if (!price) {
    return options.emptyText || "价格已包含";
  }

  const prefix = options.addPrefix ? "¥" : options.customPrefix || "";
  const formattedPrice = `${Math.abs(price)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return `${price < 0 ? '-' : ''}${prefix}${formattedPrice}`;
}


export function showLoading() {
  const toast = Toast.loading({
    message: "加载中",
    forbidClick: true,
    loadingType: "spinner",
    duration: 200,
  });
  return () => {
    toast.clear();
  };
}

export function getTokens() {
  let accessToken = null;
  let refreshToken = null;

  if (isApp()) {
    accessToken = VueCookies.get("aId");
    refreshToken = VueCookies.get("rId");
  } else {
    accessToken = route.currentRoute._value.query.aId || VueCookies.get("aId") || '';
    refreshToken = route.currentRoute._value.query.rId || VueCookies.get("rId") || '';
  }
  return {
    aId: accessToken,
    rId: refreshToken,
  };
}
const ua = typeof window === "object" ? window.navigator.userAgent : "";

let _isIOS = -1;
let _isAndroid = -1;

export function isIOS() {
  if (_isIOS === -1) {
    _isIOS = /iPhone|iPod|iPad/i.test(ua) ? 1 : 0;
  }
  return _isIOS === 1;
}

// export function isAndroid() {
//   if (_isAndroid === -1) {
//     _isAndroid = /Android/i.test(ua) ? 1 : 0;
//   }
//   return _isAndroid === 1;
// }
// 键盘收起弹出事件
export function focusout(focusEvent, outEvent) {
  if (isAndroid()) {
    const innerHeight = window.innerHeight;
    window.addEventListener("resize", () => {
      const newInnerHeight = window.innerHeight;
      if (innerHeight > newInnerHeight) {
        // 键盘弹出事件处理
        focusEvent && focusEvent();
      } else {
        // 键盘收起事件处理
        outEvent && outEvent();
      }
    });
  } else if (isIOS()) {
    window.addEventListener("focusin", (e) => {
      // 键盘弹出事件处理
      var node = document.activeElement; //当前focus的dom元素
      if (['radio', 'select', 'button'].includes(node.role)) return
      setTimeout(function () {
        if (node) {
          if (node.nodeName == "TEXTAREA" || node.nodeName == "INPUT") {
            if (node.style.textShadow === "") {
              node.style.textShadow = "rgba(0,0,0,0) 0 0 0";
            } else {
              node.style.textShadow = "";
            }
          }
        }
      }, 1500);
      focusEvent && focusEvent();
    });
    window.addEventListener("focusout", () => {
      // 键盘收起事件处理
      outEvent && outEvent();
    });
  }
}
// 处理v-html中换行符
export function formatWord(val) {
  if (!val) return;
  return val.replace(/\n/g, '<br>')
}

function resolveTimeout(value, delay) {
  return new Promise((resolve) => setTimeout(() => resolve(value), delay));
}

export const getAppLoginInfo = async () => {
  if (isApp()) {
    JSCallGetLoginInfo()
    return Promise.race([new Promise((resolve) => {
      window.setLoginInfo = (aId, rId, system, userId) => {
        resolve({
          aId, rId, system, userId
        })
      }
    }), resolveTimeout({ aId: '', rId: '', system: '', userId: '' }, 500)])
  } else {
    return Promise.resolve()
  }
}
export function getAppVersion() {
  if (!window || !navigator) return
  const regex = /App_([\d.]+)/
  const match = window.navigator.userAgent.match(regex)
  if (match) {
    const version = match[1]
    return version
  }
}
/**
 * 版本号判断方法
 * @param {*} version  设定版本号
 * @returns  false 当前版本号小于设定版本号  true：ok
 */
export function isOldVersion(version) {
  if (isMiniprogramEnv()) return false
  const currentVreion = getAppVersion()
  if (!currentVreion) return false
  if (compareVersion(currentVreion, version) !== -1) {
    return false
  } else {
    return true
  }
}

/**
 * 
 * @param {*} version1 当前版本号
 * @param {*} version2 设定版本号
 * @returns -1 设定版本号大于当前版本号  0 版本号相等  1当前版本号大于设定版本号
 */
const compareVersion = function (version1, version2) {
  // 将两个版本号切割成由修订号组成的数组
  const arr1 = version1.split('.')
  const arr2 = version2.split('.')
  // 比较两个数组的长度，得到最大的数组长度
  const maxLength = Math.max(arr1.length, arr2.length)
  // 遍历数组，分别比较同一个位置上的版本号
  for (let i = 0; i < maxLength; i++) {
    // 从左到右依次比较版本号
    const a = arr1[i] || 0
    const b = arr2[i] || 0
    //  忽略前导0，使用Number()转为数字
    if (Number(a) > Number(b)) {
      return 1
    } else if (Number(a) < Number(b)) {
      return -1
    }
    // 对比结束的时候，就返回 0
    if (i === maxLength - 1) {
      return 0
    }
  }
}

export function callOldAppWindowMethodWhenAvailable(method, name) {
  // 定义一个内部函数，用于检查方法是否存在并进行调用
  function checkAndCallMethod() {
    if (window && window[method] && typeof window[method]['postMessage'] === 'function') {
      // 调用方法，并传递参数
      window[method]['postMessage'](name);
    } else {
      // 方法不存在或不是一个函数，等待一段时间后重新检查
      setTimeout(checkAndCallMethod, 100); // 设置适当的延迟时间
    }
  }

  // 第一次调用检查函数
  checkAndCallMethod();
}

export function clearUrlParams(url, paramsToClear) {
  // 遍历要置空的参数数组
  paramsToClear.forEach(param => {
    // 创建正则表达式，匹配指定参数名和值，支持没有等号的情况
    const regex = new RegExp(`(${param}(=[^&]*)?)(&|$)`, 'gi');
    // 将匹配到的参数值置空，并处理掉多余的`&`
    url = url.replace(regex, (match, p1, p2, p3) => p3 === '&' ? p3 : '');
  });

  // 处理多余的 '&' 符号
  url = url.replace(/&{2,}/g, '&'); // 替换多个连续的 '&'
  url = url.replace(/\?&/g, '?'); // 移除 '?&' 组合

  // 处理结尾多余的 '?' 和 '&'
  url = url.replace(/[&?]$/, '');

  // 返回更新后的URL
  return url;
}

/**
 * 这个函数用于在一个指定的HTML元素中动态显示一个数字，
 * 从初始值递增到最大值。它使用一个定时器，每过一秒钟，
 * 数值增加的数量会根据当前数值与最大值之间的差值而变化。
 * 当数值达到最大值时，将显示最大值，并停止计时器。
 * @param {*} nums 
 * @param {*} maxNums 
 * @param {*} byId 
 */
export function numRunFun(nums, maxNums, byId) {
  let numBox = document.getElementById(byId);
  let num = 0;
  let maxNum = Number(maxNums);
  const setup = Number(Number(maxNums / 100).toFixed(2))
  const timer = setInterval(function () {
    num = Number(Number(num + setup).toFixed(2))
    if (num >= maxNum || !maxNum || maxNum < 1) {
      numBox.innerHTML = maxNums
      clearInterval(timer);
    } else {
      numBox.innerHTML = Math.floor(num);
    }
  }, 0);
}

/**
 * 图片url 转Base64文件
 * @param {*} url 
 * @returns Promise对象
 */
export const getBase64 = (url) => {
  return new Promise((resolve) => {
    let Img = new Image()
    let dataURL = ""
    Img.src = url + "?v=" + Math.random()
    Img.setAttribute("crossOrigin", "Anonymous")
    Img.onload = function () {
      var canvas = document.createElement("canvas"),
        width = Img.width,
        height = Img.height
      canvas.width = width
      canvas.height = height
      canvas.getContext("2d").drawImage(Img, 0, 0, width, height)
      dataURL = canvas.toDataURL("image/jpeg")
      resolve(dataURL)
    }
  })
}
// 将px单位转成vw响应式布局
export function replacePx2Vw(content) {
  const regex = /(\d+)px/g
  const newContent = content.replace(regex, function (match, p1) {
    const vwValue = (p1 / 375) * 100
    return vwValue + 'vw'
  })
  return newContent
}
// 导出一个函数，用于在URL中替换或添加参数
export function replaceOrAddUrlParam(url, param, value) {
  // 创建一个正则表达式，用于匹配URL中是否已存在指定参数
  const regex = new RegExp(`([?&])${param}=.*?(&|$)`, "i")
  // 检查URL中是否已存在指定参数
  const hasParam = url.match(regex)

  // 如果URL中没有指定参数，则在URL中添加参数
  if (!hasParam) {
    const separator = url.indexOf("?") !== -1 ? "&" : "?"
    return url + separator + `${param}=${value}`
  }

  // 如果URL中已存在指定参数，则替换URL中的参数
  return url.replace(regex, `$1${param}=${value}$2`)
}
export function removeNullParamFromUrlRegex(url, param){
  let regex = new RegExp(param + '=null(&|$)', 'g');
  return url.replace(regex, '');
}

// 设置导航栏透明
export function setBarTransparent(isTransparent, bgAlpha = '0') {
  if (isApp()) {
    if (String(isTransparent) === 'true') {
      setBarColor({
        bgColor: 'FFFFFF',
        iconColor: "000000",
        bgAlpha,
        iconAlpha: '1.0',
        transparentStyle: true
      })
    } else {
      setBarColor({
        bgColor: 'F6F6F6',
        iconColor: "000000",
        bgAlpha: '1.0',
        iconAlpha: '1.0',
        transparentStyle: false
      })
    }
  }
}

export const getNavBarHeight = () => {
  if (isApp()) {
    JSCallGetAppBarHeight()
    return Promise.race([new Promise((resolve) => {
      window.getAppBarHeight = (top, bar) => {
        resolve({
          taskbarHeight: top,
          barHeight: bar
        })
      }
    }), resolveTimeout({ taskbarHeight: 40, barHeight: 100 }, 500)])
  } else {
    return Promise.resolve({ taskbarHeight: 0, barHeight: 0 })
  }
}
export function getCityCodeByCityName(type, value) {
  console.log(type, value)
  const { city_list, province_list } = areaList
  let cityCode = ''
  let provinceCode = ''
  let cityName = ''
  let provinceName = ''
  for (const key in city_list) {
    if (type === 'cityCode' && String(key) == value) {
      cityCode= value
      cityName = city_list[key]
      provinceCode = String(replaceCode(key, 4))
      provinceName = province_list[Number(provinceCode)]
    }
    if (type === 'cityName' && city_list[key] === value) {
      cityCode= String(key)
      cityName = city_list[key]
      provinceCode = String(replaceCode(key, 4))
      provinceName = province_list[Number(provinceCode)]
    }
  }

  console.log('getCityCodeByCityName', JSON.stringify({
    cityCode,
    cityName,
    provinceCode,
    provinceName
  }))
  return {
    cityCode,
    cityName,
    provinceCode,
    provinceName
  }
}
// 包装函数，用于设置最大响应时间
function withTimeout(asyncFunc, timeout, defaultValue) {
  return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
          resolve(defaultValue);
      }, timeout);

      asyncFunc().then((result) => {
          clearTimeout(timer);
          resolve(result);
      }).catch((err) => {
          clearTimeout(timer);
          reject(err);
      });
  });
}
const getLocationByApp = function() {
  return new Promise((resolve) => {
    JSGetLocationFromApp()
    window.getAppLocation = function (data) {
      const cityCode = replaceCode(data, 2)
      console.log('getLocationByApp:', JSON.stringify(data))
      resolve(getCityCodeByCityName('cityCode', String(cityCode)))
    }
  })
  
}
export function getLocationByAmap() {
  return new Promise((resolve, reject) => {
    const defaultAddresInfo = {
      cityCode: '',
      cityName: '',
      provinceCode: '',
      provinceName: '',
    }
    const jsonParams = {
      key: "76a1bb1af0fd590fd96cad56383a560a"
    }
    const { isDyapp } = getMiniprogramEnv()
    if (isDyapp) resolve(defaultAddresInfo)
    jsonp("https://restapi.amap.com/v3/ip", jsonParams)
      .then((data) => {
        if (data) {
          console.log('amap-data: ', JSON.stringify(data))
          let cityName = (Array.isArray(data.city) || data.city === '[]') ? '' : data.city
          if (cityName) {
            resolve(getCityCodeByCityName('cityName', cityName))
          } else {
            if (isMiniprogramEnv()) {
              // 调用小程序的定位
              cityName = getParam("cityName") || "杭州市"
              resolve(getCityCodeByCityName('cityName', cityName))
            } else if (isApp()) {
              withTimeout(getLocationByApp, 4000, defaultAddresInfo).then(res => {
                resolve(res)
              })
            } else {
              resolve(defaultAddresInfo)
            }
          }
        }
      })
      .catch(() => {
        reject({})
      })
  })
}
export function  getWebpImgUrl(url) {
  return url.indexOf('x-oss-process=image') > -1 ? url : url + '?x-oss-process=image/format,webp'
}
export function processInput(input) {
  if (input === "null" || input === "undefined" || input === null || input === undefined || input === '' || input === '[]') {
      return null;
  } else {
      return input;
  }
}
// 函数重复间隔n秒执行n次
export function repeatFunction(myFunction, interval, maxExecutions) {
  let executions = 0;
  
  const intervalId = setInterval(() => {
      myFunction();
      executions++;
      
      if (executions >= maxExecutions) {
          clearInterval(intervalId);
      }
  }, interval * 1000); // interval is in seconds, so convert to milliseconds
}

export function clearLocalStorageAndCookies(param) {
  // 清理localStorage中的指定参数
  localStorage.removeItem(param);

  // 清理cookie中的指定参数
  function deleteCookie(name) {
      document.cookie = name + '=; Max-Age=0; path=/';
  }

  deleteCookie(param);

  console.log(`${param} 已从localStorage和cookies中删除`);
}

