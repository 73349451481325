import _objectSpread from "/var/lib/jenkins/workspace/radar-order-views-prod/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.promise.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import cookies from 'vue-cookies';
import { isApp, getAppLoginInfo, isOldVersion, getNavBarHeight, setBarTransparent, isLogin } from '@/utils/utils';
import CommonHeaderBar from "@components/commonHeaderBar";
import { waitForJsBridge } from '@/utils/jsbridge';
import { getUserMessage } from "@/api/newCar";
import sensors from '@/utils/sensors_config.js';
import { isMiniprogramEnv, getParam, clearLocalStorageAndCookies, isNull } from '@/utils/utils';
export default {
  components: {
    CommonHeaderBar
  },

  data() {
    return {
      includeList: [],
      showPage: !isApp() || isOldVersion('2.7.0'),
      isShowCommonHeader: false
    };
  },

  watch: {
    $route: function $route(val, oldval) {
      if (val.meta && val.meta.keepAlive && this.includeList.indexOf(val.fullPath) === -1) {
        this.includeList.push(val.fullPath);
      }

      if (!isApp()) return;

      if (val.meta.navBarStyle) {
        this.isShowCommonHeader = val.meta.navBarStyle.transparentStyle;
        const navBarBackgroundColor = val.meta.navBarStyle.backgroundColor;
        this.$store.commit('common/setNavBarBackgroundColor', navBarBackgroundColor);
        waitForJsBridge(() => {
          setBarTransparent(val.meta.navBarStyle.transparentStyle);
        }); // 设置页面通顶效果
      } else {
        if (oldval.meta && oldval.meta.navBarStyle) {
          waitForJsBridge(() => {
            setBarTransparent(false);
          });
        }

        this.isShowCommonHeader = false;
      }
    }
  },
  computed: {
    showCommonHeader() {
      return this.isShowCommonHeader && isApp() && !isOldVersion('3.1.0');
    }

  },

  created() {
    this.pageInit();
    this.setLoginInfo();
    this.getLoginInfo();
  },

  mounted() {
    document.getElementById('page-loading').style.display = 'none'; //  解决登录后aid和rid没有替换导致请求toke有误

    setTimeout(() => {
      !isApp() && this.setSensorsLogin();
    }, 1000);
  },

  methods: {
    pageInit() {
      if (isApp()) {
        cookies.remove('aId');
        cookies.remove('rId');
        cookies.remove('userId');
        localStorage.removeItem("userId");
      } else if (isMiniprogramEnv()) {
        if (isNull(getParam('aId')) || isNull(getParam('rId'))) {
          this.clearLocalUserInfo();
        }
      }
    },

    clearLocalUserInfo() {
      clearLocalStorageAndCookies('aId');
      clearLocalStorageAndCookies('rId');
      clearLocalStorageAndCookies('userId');
      clearLocalStorageAndCookies('userInfo');
    },

    getLoginInfo() {
      if (!isApp()) return;
      const that = this;
      window.addEventListener("flutterInAppWebViewPlatformReady", function () {
        getAppLoginInfo().then(res => {
          cookies.set('aId', res.aId || '');
          cookies.set('rId', res.rId || '');
          cookies.set('userId', res.userId || '');
          that.setSensorsLogin(res.userId);
          that.showPage = true;
        });
        getNavBarHeight().then(data => {
          that.$store.commit('common/setBarHeight', {
            taskbarHeight: data.taskbarHeight,
            barHeight: data.barHeight
          });
        });
      });
    },

    setLoginInfo() {
      var _this = this;

      window.setLogin = async function () {
        let aId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        let rId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        let userId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
        cookies.set('aId', aId || '');
        cookies.set('rId', rId || '');
        cookies.set('userId', userId || '');
        localStorage.setItem("userId", userId);
        await _this.$router.replace({
          path: _this.$route.path,
          query: _objectSpread(_objectSpread({}, _this.$route.query), {}, {
            aId,
            rId,
            userId
          })
        }); // 修复app登录跳转回来安卓页面刷新白屏bug 多次刷新引起的bug
        // this.$nextTick(()=>{
        //   // window.location.reload()
        // })

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      };
    },

    setSensorsLogin(userId) {
      if (userId) {
        sensors.login(userId);
        sensors.registerPage({
          'radar_userId': userId
        });
      } else if (isLogin()) {
        getUserMessage().then(res => {
          if (res != null) {
            sensors.login(res.userId);
            sensors.registerPage({
              'radar_mobile': res.mobile,
              'radar_userId': res.userId
            });
          }
        });
      }
    }

  }
};